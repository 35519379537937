// signUpUtils.js
import { API_BASE_URL } from '../config/apiConfig';
import { toast } from 'sonner';

export const handleTogglePassword = (showPassword, setShowPassword) => {
    setShowPassword(!showPassword);
};

export const handleToggleCPassword = (showCPassword, setShowCPassword) => {
    setShowCPassword(!showCPassword);
};

export const validatePassword = (password, setPasswordValid) => {
    const length = password.length >= 8;
    const number = /\d/.test(password);
    const capital = /[A-Z]/.test(password);
    const special = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValid({ length, number, capital, special });
};

export const createUser = async (data, setLoading, toast, navigate) => {
    setLoading(true);
    try {
        const response = await fetch(`${API_BASE_URL}/api/admin/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: data,
        });

        if (response.status === 200) {
            const responseData = await response.json();
            console.log(responseData)
            const { token } = responseData;
            localStorage.setItem('access_token', token);


            navigate('/funnel');
        } else {
            let errorMessage = 'An error occurred';
            try {
                const result = await response.json();
                console.log(response, result)
                errorMessage = result.error || errorMessage;
            } catch (jsonError) {
                console.error('Error parsing JSON response:', jsonError);
            }
            toast.error(errorMessage);
        }
    } catch (error) {
        console.error('An error occurred:', error);
        toast.error('Failed to create user. Please try again later.');
    } finally {
        setLoading(false);
    }
};

