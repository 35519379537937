import "./showmore.css";

const ShowMoreModal = ({ show, onClose, referral }) => {
    if (!show || !referral) {
        return null; // If modal is not visible or no referral data, return nothing
    }

    // Function to stop event propagation when clicking inside the modal content
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={handleModalClick}>
                {/* Close button */}
                <button className="w-fit ml-auto text-black p-[5px] rounded-lg mt-2" onClick={onClose}>
                    X
                </button>
                <div className="border-solid p-[5px]">
                    {/* Display the correct referral data */}
                    <p>Users with referral link: {referral.referralUsers}</p>
                    <p>Users with Projects: {referral.referralsWithProjects}</p>
                    <p>Collaborators: {referral.referralCollaborators}</p>
                    <p>Users Subscribed: {referral.subscribedReferrals}</p>
                </div>
            </div>
        </div>
    );
};

export default ShowMoreModal;
