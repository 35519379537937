// utils.js
import { API_BASE_URL } from '../config/apiConfig.js';

export const handleTogglePassword = (showPassword, setShowPassword) => {
    setShowPassword(!showPassword);
};

export const login = async (data, setLoading, navigate, rememberMe, toast) => {
    setLoading(true);
    try {
        const response = await fetch(API_BASE_URL + '/api/admin/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {
            const responseData = await response.json();
            console.log(responseData)
            const { token } = responseData;
            if (token) {
                localStorage.setItem('access_token', token);

                navigate('/funnel');
                if (rememberMe) {
                    localStorage.setItem('rememberMe', true);
                } else {
                    localStorage.removeItem('rememberMe');
                }

                console.log('Login successful:', responseData);
            } else {
                toast.error('Login failed: Missing token in response.');
            }
        } else {
            const result = await response.json();
            toast.error(result.error || 'Login failed. Please try again.');
        }
    } catch (error) {
        console.error('An error occurred:', error);
        toast.error('An error occurred. Please try again later.');
    } finally {
        setLoading(false);
    }
};

